/*global jQuery, console, window, document, WOW, setTimeout, requestAnimationFrame, XMLHttpRequest, Image, alert, TweenLite, Power2, Instafeed, lory */

(function () {
    'use strict';
    var scrollTop,
        forms = document.querySelectorAll('.ajax-form'),
        // Make forEach available for NodeList
        forEach = Array.prototype.forEach,
        html = document.querySelector('html'),
        // navIcon = document.getElementById('navicon'),
        // navClose = document.getElementById('close-nav'),
        // navMobile = document.getElementById('nav-mobile'),
        parallaxImages,
        xhr = new XMLHttpRequest(),
        newsInfo = document.getElementById('info-single'),
        newsInfoPos,
        contentSection = document.querySelector('.content-wrap'),
        mainNav = document.querySelector('.mainnav');
//        topScroller = document.getElementById('scrolltop');


    // Cross browser requestAnimationFrame
    window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function (f) {
        setTimeout(f, 1000 / 60);
    };


    // Check if element is visible in viewport
    function isElementInViewport(el) {
        var rect = el.getBoundingClientRect();
        return rect.bottom > 0 &&
            rect.right > 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */ &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight);
    }


    // Parallax scrolling based on requestAnimationFrame
    function tick() {
        forEach.call(parallaxImages, function (el) {
            // Only perform the parallax animation if the parent is visible in the viewport
            if (isElementInViewport(el.parentNode)) {
                // Subtract parentNode's offset to allow for parallax in the middle of the page.
                el.style.transform = "translate3d(0," + Math.ceil(scrollTop * 0.5) + "px, 0)";
                el.style.msTransform = "translate3d(0," + Math.ceil(scrollTop * 0.5) + "px, 0)";
                el.style.mozTransform = "translate3d(0," + Math.ceil(scrollTop * 0.5) + "px, 0)";
                el.style.webkitTransform = "translate3d(0," + Math.ceil(scrollTop * 0.5) + "px, 0)";
            }
        });
        if (newsInfo) {
            if (scrollTop >= newsInfoPos) {
                newsInfo.classList.add('fixed');
            } else {
                newsInfo.classList.remove('fixed');
            }
        }
//        if (scrollTop >= 400) {
//            topScroller.classList.add('visible');
//        } else {
//            topScroller.classList.remove('visible');
//        }
    }

    function updateUnits() {
        scrollTop = window.pageYOffset;
        requestAnimationFrame(tick);
    }

    // Add appropriate event handling for page load
    window.addEventListener("load", function () {
        // Get the collection of parallax images after everything is in place
        parallaxImages = document.querySelectorAll('.parallax-image');
        if (newsInfo) {
            newsInfoPos = newsInfo.offsetTop;
        }
        // Scroll handling
        window.addEventListener('scroll', function () { // on page scroll
            scrollTop = window.pageYOffset;
            requestAnimationFrame(tick);
        }, false);
        updateUnits();
    }, false);

    // Update units on resize as well
    window.addEventListener('resize', function () {
        updateUnits();
    }, false);

    // Feature detection
    // Prüfe ob JavaScript aktiviert ist
    html.classList.remove('no-js');
    html.classList.add('js');


    // Prüfe ob es sich um einen Touch-Screen handelt
    function is_touch_device() {
        return window.hasOwnProperty('ontouchstart');
    }

    if (is_touch_device()) {
        html.classList.add('touch');
    } else {
        html.classList.add('no-touch');
    }


    // Prüfe ob SVGs dargestellt werden können
    if (!document.createElement('svg').getAttributeNS) {
        html.classList.add('no-svg');
    } else {
        html.classList.add('svg');
    }


    // Fancy labels on contact form
    function fancyLabels(inputs) {
        forEach.call(inputs, function (elem) {
            elem.addEventListener('focus', function (ev) {
                // On focus add class to corresponding label
                ev.target.previousElementSibling.classList.add('active');
            });
            elem.addEventListener('blur', function (ev) {
                // On blur, remove class - only if textfield is empty though!
                if (ev.target.value === '') {
                    ev.target.previousElementSibling.classList.remove('active');
                }
            });
            if (elem.value !== '') {
                // Automagically (TM) add class for label if the field is prefilled.
                elem.previousElementSibling.classList.add('active');
            }
        });
    }


    // XHR handling for forms
   /* function ajaxForms(forms) {
        forEach.call(forms, function (form) {
            // Get the success message and submit button for later
            var message = form.querySelector('.success-message'),
                submitButton = form.querySelector('button.button-submit');
            // Iterate through forms NodeList & attach event listener
            message.style.display = 'none';
            form.addEventListener('submit', function (event) {
                var data = '',
                    formData = [],
                    bustCache = '?' + new Date().getTime();
                event.preventDefault();
                event.stopPropagation();
                // Serialize form input values (URI encoded) into an array for later!
                forEach.call(form.querySelectorAll('input, select, textarea'), function (input) {
                    // Only store input values in array if the input is neither disabled nor empty
                    if (!input.getAttribute('disabled') && input.value !== '') {
                        formData.push(input.getAttribute('name') + '=' + encodeURI(input.value));
                    }
                });
                // Join the form values into a query string
                data = formData.join('&');
                xhr.onreadystatechange = function () {
                    // State 1 means OPENED
                    if (xhr.readyState === 1) {
                        // Add visual feedback for the AJAX request being sent!
                        submitButton.classList.add('sending');
                    }
                    // State 4 & Status 200 means FINISHED and OKAY
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        form.replaceChild(message, form.querySelector('.formbody'));
                        message.style.display = 'block';
                    }
                    if (xhr.readyState !== 1 && xhr.status !== 200) {
                        // Only remove the class if it explicitly does not work!
                        submitButton.classList.remove('sending');
                    }
                };
                // Send the POST request via XHR, bust the cache to avoid errors
                xhr.open('POST', '/' + form.getAttribute('action') + bustCache, true);
                xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
                xhr.send(data);
            });
        });
    } */

    // Slider content element - using lory.js
    (function (sliders) {
        if (sliders !== undefined && sliders) {
            forEach.call(sliders, function (slider) {
                var slides = slider.querySelectorAll('.js_slide'),
                    visibleSlides = [],
                    // Get client screen position of slider frame
                    frameRect = slider.getBoundingClientRect();
                forEach.call(slides, function (slide) {
                    // Compare position of left side of slide to right side of frame
                    // to see whether it's visible
                    if (slide.getBoundingClientRect().left < frameRect.right) {
                        // Put it into an array, maybe we'll need that later
                        visibleSlides.push(slide);
                    }
                });
                lory(slider, {
                    classNamePrevCtrl: 'js_prev',
                    classNameNextCtrl: 'js_next',
                    // Set infinity parameter to number of visible slides
                    infinite: visibleSlides.length
                });
            });
        }
    }(document.querySelectorAll('.js_slider')));


    // Carousel slider - using lory.js as well
    (function (carousels) {
        if (carousels !== undefined && carousels) {
            forEach.call(carousels, function (carousel) {
                var slides = carousel.querySelectorAll('.js_slide'),
                    visibleSlides = [];
                forEach.call(slides, function (slide) {
                    // Because carousels usually fill the whole screen's width,
                    // let's simly use our simple in-viewport check
                    if (isElementInViewport(slide)) {
                        // Push visible slides into an array, because maybe we'll need that later
                        visibleSlides.push(slide);
                    }
                });
                lory(carousel, {
                    classNamePrevCtrl: 'js_prev',
                    classNameNextCtrl: 'js_next',
                    // Get the number of slides to move at once from the element's attributes
                    slidesToScroll: parseInt(carousel.getAttribute('data-moveslides'), 10),
                    // Set infinity parameter to equal the number of visible slides
                    infinite: visibleSlides.length,
                    rewind: false
                });
            });
        }
    }(document.querySelectorAll('.js_carousel')));


    // Colorboxify die entsprechenden Links
    jQuery('a.colorbox').colorbox({
        close: 'Schließen',
        maxHeight: '80%',
        maxWidth: '95%'
    });
    jQuery('a[data-lightbox]').colorbox({
        rel: 'artikel',
        previous: 'Zurück',
        next: 'Weiter',
        current: false,
        close: 'Schließen',
        maxHeight: '80%',
        maxWidth: '95%'
    });


    // Scroll to top handler
//    topScroller.addEventListener('click', function (event) {
//        event.preventDefault();
//        // Using GSAP TweenLite and the scrollToPlugin here
//        TweenLite.to(window, 2, { scrollTo: {y: 0}, ease: Power2.easeInOut });
//    });

    // Scrolling for teaser buttons
    (function (teaserButton) {
        if (teaserButton !== 'undefined' && teaserButton) {
            teaserButton.addEventListener('click', function (ev) {
                ev.preventDefault();
                var contentStart = contentSection.offsetTop;
                TweenLite.to(window, 2, { scrollTo: {y: contentStart}, ease: Power2.easeInOut });
            });
        }
    }(document.querySelector('a.teaser-button')));


    (function (scrollButton) {
        if (scrollButton !== 'undefined' && scrollButton) {
            var content = document.querySelector('section.container').offsetTop;
            scrollButton.addEventListener('click', function (ev) {
                ev.preventDefault();
                TweenLite.to(window, 1, { scrollTo: {y: content}, ease: Power2.easeInOut});
            });
        }
    }(document.querySelector('.scroll-button')));

    (function (navButton) {
        navButton.addEventListener('click', function (ev) {
            ev.preventDefault();
            ev.stopPropagation();
            mainNav.classList.toggle('open');
        });
    }(document.getElementById('navbutton')));


    /*ajaxForms(forms);*/
    fancyLabels(document.querySelectorAll('.fancy-form input[type="text"], .fancy-form input[type="number"], .fancy-form input[type="tel"], .fancy-form input[type="email"], .fancy-form input[type="url"], .fancy-form textarea'));

	/** search box crap */
    jQuery('#search-icon').on('click',function() {
	    jQuery('form.sucheingabe').css('opacity', 0)
								 .slideDown('slow', function() {
										jQuery(this).find('input.text').focus();
								})
								 .animate(
								   	{ opacity: 1 },
									{ queue: false, duration: 'slow' }
								 );
	});
	jQuery('div.close .fa-times').on('click',function() {
	    jQuery('form.sucheingabe')
								 .slideUp('slow')
								 .animate(
								   { queue: false, duration: 'slow' }

								 );
	});
	/* end search box crap **/
}(window));
